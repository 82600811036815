@import './reset.css';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap');

:root {
	/* Colors */
	--blackpure: #000;
	--black: #171718;
	--black-border: #26292d;
	--white: #fff;
	--purple: #fff000;

	/* Nav */
	--nav-bg: var(--black);
	--nav-border: var(--black-border);
	--nav-text: var(--white);

	/* Header */
	--header-bg: var(--black);
	--header-text: var(--white);

	/* Footer */
	--footer-bg: var(--black);
	--footer-text: var(--white);

	/* Light mode */
	--page-bg: var(--white);
	--text-color: var(--black);
	--accent: var(--purple);
	--title-1: var(--accent);

	--project-card-bg: var(--white);
	--project-card-text: var(--black);
	--box-shadow: 0px 5px 35px #fff000;
}

.dark {
	/* Dark mode */
	--page-bg: #252526;
	--text-color: var(--white);

	--title-1: var(--white);

	--project-card-bg: var(--black);
	--project-card-text: var(--white);
	--box-shadow: 0px 5px 35px #fff000;
}

html,
body {
	min-height: 100vh;

	font-family: 'DM Sans', sans-serif;
	letter-spacing: -0.5px;

	/* Light mode */
	background-color: var(--page-bg);
	color: var(--text-color);
}

#root {
	min-height: 100vh;
}

.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

/* General */

.container {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1200px;
}

.none {
	display: none !important;
}

.section {
	padding: 70px 0;
}

.title-1 {
	margin-bottom: 60px;

	font-size: 60px;
	font-weight: 700;
	line-height: 1.3;
	color: var(--title-1);
	text-align: center;
}

.title-2 {
	margin-bottom: 20px;

	font-size: 40px;
	font-weight: 700;
	line-height: 1.3;
}





/* Btn */

.btn {
	display: inline-block;
	height: 48px;
	padding: 12px 28px;

	border-radius: 5px;
	background-color: var(--accent);
	color: var(--white);
	letter-spacing: 0.15px;
	font-size: 16px;
	font-weight: 500;

	transition: opacity 0.2s ease-in;
}

.btn:hover {
	opacity: 0.8;
}

.btn:active {
	position: relative;
	top: 1px;
}

/* Btn outline */

.btn-outline {
	display: flex;
	column-gap: 10px;
	align-items: center;

	height: 48px;
	padding: 12px 20px;

	border-radius: 5px;
	border: 1px solid #000;
	background-color: #fff;
	color: #000;

	transition: opacity 0.2s ease-in;
}

.btn-outline:hover {
	opacity: 0.8;
}

.btn-outline:active {
	position: relative;
	top: 1px;
}

/* Projects  */

.projects{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.project-details{
	box-sizing: border-box;
  width: 300px;
  height: 350px; 
  box-shadow: 12px 17px 51px #fff000;
  backdrop-filter: blur(6px);
  border-radius: 17px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: bolder;
  margin-bottom: 40px;
}

.project-details:hover{
	border: 1px solid #fff000;
  transform: scale(1.05);
}

.project-title{
	color: var(--text-color);
}
.animated-count{
	margin-top: 110vh;
  }


.card-flex{
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
	flex-wrap: wrap;
	gap: 1em;
}

.rwd-table {
	margin: 1em auto;
	min-width: 300px;
	max-width: 1300px;
	background: #dddddd;
	color: #000000;
	border-radius: 0.4em;
	overflow: hidden;
  }
  
  .rwd-table tr {
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	border-color: #000000;
  }
  
  .rwd-table th {
	display: none;
  }
  
  .rwd-table td {
	display: block;
	padding: 0.5em 1em;
	text-align: left;
  }
  
  .rwd-table td:before {
	content: attr(data-th) ": ";
	font-weight: bold;
	width: 6.5em;
	display: inline-block;
	color: rgb(0, 0, 0);
  }
  .rwd-table .service-price {
	margin-bottom: 2em;
  }

  .service-title{
	font-weight: 600;
  }

  .about-video {
    display: flex;
    justify-content: space-between; /* Розділяє iframe рівномірно по горизонталі */
    flex-wrap: wrap;
    gap: 20px;
    width: 100%; /* Займає всю ширину батьківського контейнера */
}

.about-iframe-left {
    align-self: flex-start; /* Вирівнює iframe зліва */
    margin-right: auto; /* Відштовхує інші елементи вправо */
}

.about-iframe-right {
    align-self: flex-start; /* Вирівнює iframe справа */
    margin-left: auto; /* Відштовхує інші елементи вліво */
}

.about-iframe {
    margin-top: 1rem;
    width: 100%;
    max-width: 560px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    flex-shrink: 0;
}

.about-iframe:hover {
    transform: scale(1.05);
}

.about-text {
    max-width: 800px;
    margin: 0 auto;
}

.about-text p {
    line-height: 1.8;
    font-size: 1.3em;
    margin-bottom: 1.2em;
}

.about-text strong {
    color: #fbff00;
}



  
  @media (min-width: 480px) {
	

	.rwd-table {
	  display: table;
	}
  
	.rwd-table tr {
	  display: table-row;
	}
  
	.rwd-table th,
	.rwd-table td {
	  display: table-cell;
	  padding: 0.25em 0.5em;
	}
  
	.rwd-table th {
	  display: table-cell;
	  background-color: #b6b6b6;
	  color: #000000;
	}
  
	.rwd-table td:before {
	  display: none;
	}
  
	.rwd-table td:first-child {
	  padding-left: 0;
	}
  
	.rwd-table td:last-child {
	  padding-right: 0;
	}
	}
  


@media (max-width: 821px) {
	/* Header  */

	.header__title {
		font-size: 30px;
	}

	.header__title strong {
		font-size: 40px;
	}

	/* Nav */

	.nav-row {
		justify-content: space-between;
	}
	.nav-list,  .nav-list--open{
		flex-wrap: nowrap;
	}

	.nav-list--open {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        background-color: var(--nav-border);
        font-size: 20px;
        z-index: 10000;
        padding: 30px;
		flex-wrap: nowrap;
	}


	.dark-mode-btn {
		order: 0;
	}

	/* General */

	.section {
		padding: 40px 0;
	}

	.title-1 {
		margin-bottom: 30px;
		font-size: 40px;
	}

	.title-2 {
		margin-bottom: 10px;
		font-size: 30px;
	}

	.project-details{
		display: block;
	}
	
	/* Footer */

	.footer {
		padding: 40px 0 30px;
	}

	.footer__wrapper {
		row-gap: 20px;
	}

	/* Social */

	.social {
		column-gap: 20px;
	}

	.social__item {
		width: 28px;
	}

	.about-us__item{
		display: block;
	  }
	
	  .about-us__item p{
		max-width: 450px;
		margin: 0 auto;
	  }
	  .about-us__item iframe{
		width: 320px;
		height: auto;
	  }
}
.App{
	min-width: 100vw;
	margin: 0 auto;
}
.loader {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 10;
	width: 160px;
	height: 100px;
	margin-left: -80px;
	margin-top: -50px;
	border-radius: 5px;
	background: #1e3f57;
	animation: dot1_ 3s cubic-bezier(0.55,0.3,0.24,0.99) infinite;
  }
  
  .loader:nth-child(2) {
	z-index: 11;
	width: 150px;
	height: 90px;
	margin-top: -45px;
	margin-left: -75px;
	border-radius: 3px;
	background: #3c517d;
	animation-name: dot2_;
  }
  
  .loader:nth-child(3) {
	z-index: 12;
	width: 40px;
	height: 20px;
	margin-top: 50px;
	margin-left: -20px;
	border-radius: 0 0 5px 5px;
	background: #6bb2cd;
	animation-name: dot3_;
  }
  
  @keyframes dot1_ {
	3%,97% {
	  width: 160px;
	  height: 100px;
	  margin-top: -50px;
	  margin-left: -80px;
	}
  
	30%,36% {
	  width: 80px;
	  height: 120px;
	  margin-top: -60px;
	  margin-left: -40px;
	}
  
	63%,69% {
	  width: 40px;
	  height: 80px;
	  margin-top: -40px;
	  margin-left: -20px;
	}
  }
  
  @keyframes dot2_ {
	3%,97% {
	  height: 90px;
	  width: 150px;
	  margin-left: -75px;
	  margin-top: -45px;
	}
  
	30%,36% {
	  width: 70px;
	  height: 96px;
	  margin-left: -35px;
	  margin-top: -48px;
	}
  
	63%,69% {
	  width: 32px;
	  height: 60px;
	  margin-left: -16px;
	  margin-top: -30px;
	}
  }
  
  @keyframes dot3_ {
	3%,97% {
	  height: 20px;
	  width: 40px;
	  margin-left: -20px;
	  margin-top: 50px;
	}
  
	30%,36% {
	  width: 8px;
	  height: 8px;
	  margin-left: -5px;
	  margin-top: 49px;
	  border-radius: 8px;
	}
  
	63%,69% {
	  width: 16px;
	  height: 4px;
	  margin-left: -8px;
	  margin-top: -37px;
	  border-radius: 10px;
	}
  }