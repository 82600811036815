.repair-image:hover{
     transform: scale(1.05);
}

.show-more-button, .show-less-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #fff000;
    color: rgb(83, 83, 83);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}


.show-more-button:hover, .show-less-button:hover {
    background-color: #eee132;
}

/* Загальні стилі для контейнера і зображень */
.repair-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px;
    margin-top: 20px;
}

.repair-image {
    box-sizing: border-box;
    width: 200px;
    height: 100px;
    border-radius: 17px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
    object-fit: contain;
    margin-bottom: 40px;
    filter: brightness(90%);
}

.repair-image:hover {
    transform: scale(1.05);
    filter: brightness(100%);
}

/* Медіа-запит для мобільних пристроїв */
@media (max-width: 768px) {
    .repair-image {
        width: calc(20% - 20px); /* Розрахунок ширини для чотирьох стовпців */
        height: auto; /* Автоматична висота для підтримки аспектного відношення */
        margin-bottom: 20px; /* Зменшена відстань між зображеннями */
    }
}
