@import "https://fonts.googleapis.com/css?family=Dosis:300,400,500,600,700";

/* Variables */
:root {
  --bg-body: #2b2b2b;
  --red: #fff000;
  --blue: #48452b;
  --primary-color: var(--red);
  --secondary-color: var(--blue);
  --base-font: helvetica, arial, tahoma, verdana;
  --base-font-title: "Dosis", arial, tahoma, verdana;
  --base-font-color: #726f77;
  --timeline-color: var(--primary-color);
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1100px;
  margin: 0 auto;

}



/* Timeline */
#timeline {
  width: 100%;
  margin: 30px auto;
  position: relative;
  padding: 0 10px;
  transition: all .4s ease;
}

#timeline:before {
  content: "";
  width: 3px;
  height: 100%;
  background: var(--timeline-color);
  left: 50%;
  top: 0;
  position: absolute;
}

#timeline:after {
  content: "";
  clear: both;
  display: table;
  width: 100%;
}

.timeline-item {
  margin-bottom: 50px;
  position: relative;
}

.timeline-item:after, .timeline-item:before {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}

.timeline-icon {
  background: var(--timeline-color);
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  margin-left: -23px;
  border-radius: 50%;
}

.timeline-icon svg {
  position: relative;
  top: 14px;
  left: 14px;
}

.timeline-content {
  width: 45%;
  padding: 20px;
  box-shadow: 0 3px 0 rgba(0,0,0,0.1);
  border-radius: 5px;
  transition: all .3s ease;
}

.timeline-content h2 {
  padding: 15px;
  background: var(--timeline-color);
  color: #fff;
  margin: -20px -20px 0 -20px;
  font-weight: 300;
  border-radius: 3px 3px 0 0;
}

.timeline-content:before {
  content: '';
  position: absolute;
  left: 45%;
  top: 20px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid var(--timeline-color);
}

.timeline-content.right {
  float: right;
}

.timeline-content.right:before {
  right: 45%;
  left: inherit;
  border-left: 0;
  border-right: 7px solid var(--timeline-color);
}

/* Buttons */
.btn {
  padding: 5px 15px;
  text-decoration: none;
  background: transparent;
  border: 2px solid lighten(var(--primary-color), 10%);
  color: lighten(var(--primary-color), 10%);
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 5px;
  transition: background .3s ease;
  box-shadow: 2px 2px 0 lighten(var(--primary-color), 10%);
}

.btn:hover {
  box-shadow: none;
  top: 2px;
  left: 2px;
  box-shadow: 2px 2px 0 transparent;
}

/* Media Queries */
@media screen and (max-width: 821px) {
  #timeline {
    margin: 30px;
    padding: 0px;
    width: 90%;
  }
  #timeline:before {
    left: 0;
  }
  .timeline-item .timeline-content {
    width: 90%;
    float: right;
  }
  .timeline-item .timeline-content:before,
  .timeline-item .timeline-content.right:before {
    left: 10%;
    margin-left: -6px;
    border-left: 0;
    border-right: 7px solid var(--timeline-color);
  }
  .timeline-item .timeline-icon {
    left: 0;
  }
}
