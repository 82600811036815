.article {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
  }
  
  .article-wrapper {
    flex-basis: calc(33.33% - 10px); 
    margin-bottom: 20px; 
    text-align: center;
    
  }
  
  .article-image {
    width: 100%; 
    height: 200px; 
    object-fit: contain;
    background-color: #fff;
    border-radius: 1rem;
  
  }