/* Footer */

.footer {
	margin-top: auto;
	padding: 60px 0 50px;
	background-color: var(--footer-bg);
	color: var(--footer-text);
}

.footer__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 27px;
}

.social {
	display: flex;
	column-gap: 50px;
}

.copyright {
	font-size: 16px;
}

.copyright p + p {
	margin-top: 0.5em;
}

.footer .title-2{
	color: var(--footer-text);
	font-size: 20px;
}

.footer a{
	color: var(--footer-text);
}


.card{
	display: flex;
	justify-content: center;
	gap: 10px;
}

.social-list{
	display: flex;
	justify-content: center;
	gap: 50px;
}

.phone__footer-info{
	display: none;
}

.footer__navigation{
	display: flex;
	flex-direction: column;
	gap: .5em;
}

@media (max-width: 768px){
	.footer .title-2{
		font-size: 16px;
	}

	.footer p{
		font-size: 12px;
	}
}
@media (max-width: 768px) {
	.footer__wrapper{
		display: block;
	}

	.social{
		padding: 10px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		justify-content: space-evenly;

		
	}

	.social__item-logo, .social__item-link{
		display: none;
	}

	.social-list{
		display: flex;
		justify-content: flex-start;
		gap: 20px;
	}

	.social__item{
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: nowrap;
		justify-content: center;
	}

	.social__item-logo, .social__item-link, .cont{
		display: none;
	}
	.social{
		display: none;
	}
	.phone__footer-info{
		display: block;
	}
	.footer__navigation{
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		gap: 10px;
	}
}