/* main.css */

.language-prompt-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Затемнення фону */
    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index: 1000; /* Переконайтеся, що це найвищий z-index на сторінці */
  }
  
  .language-prompt {
    background-color: var(--page-bg);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 60px 5px rgba(0, 0, 0, 0.4);
    text-align: center;
  }
  
  .language-prompt p {
    margin-bottom: 20px;
  }
  
  .language-prompt .button {
    margin-right: 10px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .language-btn{
    display: flex;
    justify-content: center;
    text-align: center;

  }

  @media (max-width: 821px){
    .language-btn{
        width: 100%;
        flex-direction: column;
    }
    .LanguageSwitcher{
        width: 100%;

    }
    .button{
        width: 100%;
    }
  }