  .counters-container{
    display: flex;
    justify-content: center;
    gap: 10px;
  }


  .counter-wrapper {
    text-align: center;
    margin-bottom: 20px;
    padding: 20px;
  }

  .counter-value {
    font-size: 2em;

  }
    
  @media (max-width: 600px) {
    .counters-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .counters-section{
      margin-top: 110vh;
    }
  
  }
  