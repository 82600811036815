/* Header */
.header {
	padding: 0;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
	text-align: center;
}

.carousel .slide img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.carousel .slide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    z-index: 1;
}

.header__wrapper {
	position: absolute;
	max-width: 660px;
	z-index: 2;
    color: var(--white);
}

.header__title {
	margin-bottom: 20px;
	font-size: 40px;
	font-weight: 700;
	line-height: 1.4;
    
}

.header__title strong {
	font-size: 60px;
	font-weight: 700;
}

.header__title em {
	font-style: normal;
	color: var(--accent);
}

.header__text {
	margin-bottom: 40px;
	font-size: 18px;
	line-height: 1.333;
}

.header__text p + p {
	margin-top: 0.5em;
}

@media (max-width: 821px) {
	.header {
        margin-top: 25%;
        min-height: auto; 
    }
    
    .carousel .slide img {
        height: auto; 
        object-fit: fill;
    }

    .header__title {
        font-size: 30px;
    }

    .header__title strong {
        font-size: 45px; 
    }

    .header__text {
        font-size: 16px; 
    }
}