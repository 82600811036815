ul {
    direction: none;
}
.button.primary {
    background-color: #FFD700;
    padding: 10px 20px;
    color: #fff;
}

.nav-list__item{
    list-style: none;
}

.button.secondary {
    background-color: #6c757d;
    padding: 10px 20px;
    color: #fff;
}

/* Стилі для кнопок з ефектом при наведенні */
.button:hover {
    background-color: #FFD700;
}