/* Nav */

.nav {
	padding: 10px;
	background-color: var(--nav-bg);
	border-bottom: 1px solid var(--nav-border);
	color: var(--nav-text);
	letter-spacing: normal;
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1000;
}

.nav-row {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	column-gap: 20px;
	row-gap: 10px;
	flex-wrap: nowrap;
  max-width: 1300px;
  margin: 0 auto;
}

.logo {
	margin-right: auto;
	color: var(--nav-text);
	font-size: 24px;
	font-family: 'Poppins', sans-serif;
}

.logo strong {
	font-weight: 700;
}

.nav-list {
  display: flex;
  flex-wrap: wrap; /* Дозволяє елементам переноситися на новий рядок */
  gap: 5px; /* Відстань між елементами */
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

.nav-list > * {
  flex: 1 1 calc(33.333% - 10px); /* Ширина кожного елемента */
}

.nav-list__link {
	color: var(--nav-text);
	transition: opacity 0.2s ease-in;
}

.nav-list__link:hover {
	opacity: 0.8;
}

.nav-list__link--active {
	position: relative;
}

.nav-list__link--active::before {
	content: '';

	position: absolute;
	left: 0;
	top: 100%;

	display: block;
	height: 2px;
	width: 100%;
	background-color: var(--accent);
}

.nav-logo {
    width: 150px;
    height: auto;
}

.nav-menu-pic{
	display: none;
}

.nav-row a{ 
	color:  white;
  flex-wrap: nowrap;
}

.nav-logo-phone:hover{
    animation: shake 0.5s infinite; 
  }

.button {
    display: inline-block;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.nav-sublist {
    display: none;
	  background-color: var(--nav-bg); 
    padding: 10px; 
    border-radius: 5px; 
    position: absolute;
    height: 100vh;
    top: 60%; 
    z-index: 1000;
}

.nav-list__item-mobile {
  display: none; /* Сховати для десктопної версії */
}

.nav-list__button {
  background: none;
  border: none;
  cursor: pointer;
}

.nav-list__link {
  text-decoration: none;
  color: inherit;
}

/* Новий клас для мобільних іконок */
.nav-mobile-icons {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  display: none;
}

.nav-mobile-icons--open {
  display: flex;
  flex-direction: row-reverse;
}

.naw-row-phone-link{
  display: flex;
  align-items: center;
}

.nav-mobile-icon-button,
.nav-mobile-icon-link {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  
}

.nav-list__item-mobile {
  display: none; 
}

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-2px);
    }
    50% {
      transform: translateX(2px);
    }
    75% {
      transform: translateX(-2px);
    }
    100% {
      transform: translateX(2px);
    }
  }
  html, body {
	max-width: 100%;
	overflow-x: hidden;
  }
.phone-menu{
  display: none;
}

  @media (max-width: 821px) {
   
    .nav-list {
        display: none;
    }
    .nav-menu-pic {
        display: block;
    }
    .nav-list--open {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        background-color: var(--nav-border);
        font-size: 20px;
        z-index: 10000;
        padding: 30px;
        flex-wrap: nowrap;
    }

    .nav-sublist {
        display: none;
    }
    .nav-list__item:hover .nav-sublist {
        display: none;
    }
    .nav-sublists {
        display: none;
    }

    .nav-list__item-phone {

        display: block; 
    }

    .nav-list__item-mobile {
      display: block;
  }

  .pk_phone{
    display: none;
  }
 

  .nav-list__item-mobile {
    display: block;
}

.nav-menu-pic, .nav-close-pic {
    cursor: pointer;
}

.nav-mobile-icons {
    display: flex;
}

.nav-phone-top{
  margin-top: 35px;
}

.nav-list__item-phone .social-list{
  margin-top: 10px;
}

.nav-list__item{
  margin-bottom: 10px;
}

.nav-list__item-phone{
  margin-bottom: 10px;
}


}